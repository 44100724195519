import Layout from "../components/layout"
import React from "react"

export default function About() {
  return (
    <Layout>
      <h1>About us</h1>
      <h5>Our Mission</h5>
      <p>Help</p>
    </Layout>
  )
}
